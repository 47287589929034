import { css } from '@emotion/react';
import { SumUpLogo } from '@sumup/icons';
import type { FC } from 'react';

export const Logo: FC<Record<string, never>> = () => (
  <a
    href="https://sumup.com"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Open SumUp's homepage in a new tab"
    title="Open SumUp's homepage in a new tab"
  >
    <SumUpLogo
      css={css`
        display: block;
        max-width: 120px;
        color: var(--cui-fg-normal);
        margin: var(--cui-spacings-tera) 0;
      `}
    />
  </a>
);
