import styled from '@emotion/styled';
import { captureMessage } from '@sentry/nextjs';
import { Body, Button, Card, Headline } from '@sumup/circuit-ui';
import type { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Logo } from 'shared/components/Logo';
import useUnsupportedLocaleProtection from 'shared/hooks/routeProtection/useUnsupportedLocaleProtection';
import { getUI404PageEntries } from 'shared/infra/contentful';
import type { IUiNotFoundPageFields } from 'shared/infra/contentful/contentful';
import { dispatch404Event } from 'shared/services/tracker/events';
import type { StaticPropsParams } from 'shared/types/static-props';

export interface Props {
  content: IUiNotFoundPageFields;
  isSystemPage: true;
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto var(--cui-spacings-kilo);
`;

const StyledHeading = styled(Headline)`
  margin-bottom: var(--cui-spacings-mega);
  text-align: center;
`;

const NotFound: NextPage<Props> = ({ content }) => {
  const redirecting = useUnsupportedLocaleProtection();
  const { asPath } = useRouter();

  // capture exceptions for tracking
  useEffect(() => {
    if (!redirecting) {
      captureMessage('404 PAGE', {
        tags: { level: 'log', pages: '404', path: asPath },
      });
      void dispatch404Event({ pages: '404', path: asPath });
    }
  }, [redirecting, asPath]);

  // if we end up on the 404 page and we want to redirect the user back into the flow as we can correct the path
  // render nothing and let the user wait for the redirection
  if (redirecting) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{content.pageTitle}</title>
      </Head>

      <Main data-testid="404-page-wrapper">
        <Logo />
        <Card>
          <StyledHeading size="two" as="h1">
            {content.pageTitle}
          </StyledHeading>
          <Body css={{ marginBottom: 'var(--cui-spacings-mega)' }}>
            {content.description}
          </Body>

          <Link href="/product-selection" passHref legacyBehavior>
            <Button variant="primary">{content.buttonText}</Button>
          </Link>
        </Card>
      </Main>
    </>
  );
};

export const getStaticProps: GetStaticProps<Props> = async ({
  locale,
}: StaticPropsParams) => {
  const pageContentEntries = await getUI404PageEntries(locale);

  return {
    props: {
      content: pageContentEntries.items[0].fields,
      isSystemPage: true,
    },
    revalidate: 60,
  };
};

export default NotFound;
